import SignalsController from "controllers/admin/signals/signals_controller"

export default class extends SignalsController {
  static targets = [ "destroyComment" ]
  
  submit(event) {
    if (!this.inputsValid()) event.preventDefault()
  }

  inputsValid() {
    let validations = $(this.followupSignalsTableTarget).find("tr").map(this.validateRow).get()

    this.updateRequiredInstructionClass()

    return validations.every(Boolean)
  }

  validateRow(_, row) {
    return this.validateSelect($(row).find(".malice-select")) &&
    this.validateSelect($(row).find(".category-select")) &&
    this.validateText($(row).find(".comment-input"))
  }

  validateSelect(element) {
    const isValid = element.length === 0 || element.val().length

    if (isValid) {
      element.closest("[data-required]").removeClass("has-error")
    } else { 
      element.closest("[data-required]").addClass("has-error")
    }
    
    return isValid
  }

  validateText(element, text_validation) {
    const words = element.val().trim().split(/\s+/).filter(i => i)
    const isValid = words.length == 0 || words.length >= 5

    if (isValid) {
      element.parents("[data-required]").removeClass("has-error")
    } else {
      element.parents("[data-required]").addClass("has-error")
    }
  
    return isValid
  }

  destroyComment(event) {
    this.destroyCommentTarget.value = event.target.value.length == 0
  }
}
